import { Country } from '@/types/PaymentCountry';

export const ENABLED_COUNTRIES: Country[] = [
    {name: 'Россия', code: 'ru', currency: 'RUB'},
    {name: 'Беларусь', code: 'by', currency: 'BYN'},
    {name: 'Казахстан', code: 'kz', currency: 'KZT'},
    {name: 'Украина', code: 'ua', currency: 'UAH'},
    {name: 'Азербайджан', code: 'az', currency: 'AZN'},
    {name: 'Остальной мир', code: 'other', currency: 'USD'},
];
