import Axios from 'axios';

import { MOCK_GET_GIVEAWAYS } from '@/requests/Giveaway/mockGetGiveaways';
import { IGiveaway } from '@/types/IGiveaway';
import axiosRetry from 'axios-retry';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export default async function getGiveaways(): Promise<IGiveaway[]> {
  if (process.env.NODE_ENV === 'development') {
    return MOCK_GET_GIVEAWAYS;
  }
  const response = await Axios.post<{ giveaways: IGiveaway[] }>('/api/giveaways/get');
  return response.data.giveaways;
}
