import { Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import getGiveaways from '@/requests/Giveaway/getGiveaways';
import { IGiveaway } from '@/types/IGiveaway';

export interface GiveawayState {
    giveaways: IGiveaway[];
    isLoading: boolean;
    isCached: boolean;
}

const GIVEAWAYS_PLACEHOLDER = {
    giveaways: [],
    isLoading: true,
    isCached: false
};

@Module({namespaced: true, name: 'giveaway', store})
export class GiveawayModule extends VuexModule implements GiveawayState {
    public giveaways: GiveawayState['giveaways'] = [];
    public isLoading: boolean;
    public isCached: boolean;

    @MutationAction({mutate: ['giveaways']})
    public async fetchGiveaways(options: { refetch?: boolean }) {
        try {
            if (!this.isCached || !!options?.refetch) {
                const giveaways = await getGiveaways();
                return {giveaways, isLoading: false, isCached: true};
            }
        } catch (err) {
            console.error('Error fetching giveaways', err);
            return {...GIVEAWAYS_PLACEHOLDER};
        }
    }

    @MutationAction({mutate: ['giveaways']})
    public async joinGiveaway({giveawayId, socket}: { giveawayId: number, socket?: boolean }) {
        const giveaways = this.giveaways.map(giveaway => ({
            ...giveaway,
            ...(giveawayId === giveaway.id ? {
                ...(!socket ? {
                    isInGiveaway: true,
                    isMetConditions: true
                } : {
                    users: giveaway.users + 1,
                })
            } : {})
        }));

        return {isCached: this.isCached, isLoading: this.isLoading, giveaways};
    }

}
