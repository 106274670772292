import { Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import getEventTasks, { GetEventTasksResponse } from '@/requests/Event/getTasks/getEventTasks';
import getEventTop, { EventTopFilter, GetEventTopResponse } from '@/requests/Event/getTop/getEventTop';
import { ta } from 'date-fns/locale';

export interface EventState {
    top: { [key in EventTopFilter]: { data: GetEventTopResponse; isLoading: boolean, isCached: boolean } };
    tasks: { data: GetEventTasksResponse; isLoading: boolean, isCached: boolean };
}

const TOP_PLACEHOLDER = {
    data: {
        players: new Array(10),
        prizeItems: new Array(10),
        userPlace: undefined,
        userScore: undefined,
        userWins: undefined
    },
    isLoading: true,
    isCached: false
};
const TASKS_PLACEHOLDER = {data: {tasks: [], arcaneItems: [], finalItems: []}, isLoading: true, isCached: false};

interface RefetchOption {
    refetch?: boolean;
}

@Module({namespaced: true, name: 'event', store})
export class EventModule extends VuexModule implements EventState {
    public top: EventState['top'] = {
        month: TOP_PLACEHOLDER,
        day: TOP_PLACEHOLDER,
        all: TOP_PLACEHOLDER
    };
    public tasks: EventState['tasks'] = TASKS_PLACEHOLDER;

    @MutationAction({mutate: ['tasks']})
    public async fetchTasks(options: RefetchOption) {
        try {
            if (!this.tasks.isCached || !!options?.refetch) {
                const data = await getEventTasks();

                return {tasks: {data, isLoading: false, isCached: true}};
            }
        } catch (err) {
            console.error('Error loading event tasks', err);
            return {tasks: TASKS_PLACEHOLDER};
        }
    }

    @MutationAction({mutate: ['top']})
    public async fetchTop(options: RefetchOption & { filter: EventTopFilter }) {
        try {
            if (!this.top[options.filter].isCached || !!options?.refetch) {
                const data = await getEventTop(options.filter);
                return {top: {...this.top, [options.filter]: {data, isLoading: false, isCached: true}}};
            }
        } catch (err) {
            console.error('Error loading event top', err);
            return {
                top: {
                    data: TOP_PLACEHOLDER,
                    isLoading: false,
                    isCached: false
                }
            };
        }
    }

    @MutationAction({mutate: ['tasks']})
    public async completeCurrentTask() {
        const tasks = this.tasks.data?.tasks ?? [];

        const currentTaskIndex = tasks.findIndex(task => task.current === true);
        if (currentTaskIndex > -1) {
            tasks[currentTaskIndex].current = false;

            const newTaskIndex =
                currentTaskIndex === tasks.length - 1
                    ? 0
                    : currentTaskIndex + 1;
            tasks[newTaskIndex] = {
                ...tasks[newTaskIndex],
                current: true,
            };
        }

        return {tasks: {...this.tasks, data: {...this.tasks.data, tasks}}};
    }


}
