import { IGiveaway } from '@/types/IGiveaway';
import { addDays } from 'date-fns';

export const MOCK_GET_GIVEAWAYS: IGiveaway[] = [{
  id: 79,
  users: 546,
  status: 0,
  rollAt: addDays(new Date(), 1),
  type: 'refillBalance',
  conditions: { refillBalance: 5 },
  isInGiveaway: false,
  isMetConditions: false,
  createdAt: addDays(new Date(), 1),
  previousWinner: {
    uuid: '4b4209d3-e97e-4a3c-a5e3-14f1b1627013',
    name: 'Olegmatebal1337',
    avatar:
      'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
  },
  item: {
    id: 283,
    name: 'Fractal Horns of Inner Abysm',
    market_hash_name: 'Fractal Horns of Inner Abysm',
    color: 'ADE55C',
    image:
      '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXL5B5QK5l9vR5PXkPCReis08qcUk93JApWia6gORVvwfHEcDlHoorjltbdz6WmauyAxD9XuZIh0rjC9tSl2Qe2-hBrNWuhJtPAdA5tZlHOug_phx4qQGw',
    price: 1555,
    type: 'item'
  },
},
  {
    id: 80,
    users: 59446,
    status: 0,
    rollAt: addDays(new Date(), 72),
    createdAt: addDays(new Date(), 1),
    type: 'completeEventTasks',
    conditions: { completeEventTasks: 5 },
    isInGiveaway: false,
    isMetConditions: false,
    previousWinner: {
      uuid: '4b4209d3-e97e-4a3c-a5e3-14f1b1627013',
      name: 'Olegmatebal1337',
      avatar:
        'https://avatars.akamai.steamstatic.com/3f35d91b13599701818afa0f51b8ca4fe02b3f21_full.jpg'
    },
    item: {
      id: 1,
      name: 'Axe',
      image:
        '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
      price: 350,
      color: 'ff8b00',
      type: 'item'
    }
  }
];
